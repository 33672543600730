import { Hub } from 'aws-amplify';

class FaceAuthClass {
    constructor() {
        
        Hub.listen('auth', (data) => {
            const { payload } = data;
            this.onAuthEvent(payload);           
            //console.log('A new auth event has happened: ', data.payload.data.username + ' has ' + data.payload.event);
        })
        //console.log('FaceAuthClass constructed.')
    }

    onAuthEvent(payload) {
        // ... your implementation
        //console.log(payload);
    }
}

export { FaceAuthClass }
