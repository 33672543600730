import React, { PureComponent } from "react";

import SignIn from "./SignIn";
import IconSelector from "./IconSelector";
import { Alert, AlertTitle } from "@mui/material";
import Grid from '@mui/material/Grid';

import { Auth } from "aws-amplify";
import AuthContent from "./AuthContent";

import OpenFaceAuthAppDialogue  from "./OpenFaceAuthAppDialogue.js"



export default class Authentication extends PureComponent {
  state = {
    username: "",
    email: "",
    password: "",
    phone_number: "",
    code: "",
    user: null, // will contain our user data object when signed in
    status: this.props.status || "SignUp",
    userfeedback:"",
    severity:"success", //alert
    title:"",
    icon: "InfoIcon",
    mode: "face",
    showFaceAuthenticatorAppDialogue: false
    
  };


  
  async componentDidMount() {
    try{
        let authUserData = await  Auth.currentAuthenticatedUser({
            bypassCache: true // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
        })
      
        if(authUserData){
            let user = {username:authUserData.username,...authUserData.attributes}
            if(user.email_verified) {
                this.setState({user, status:"content"})
            }else{
                this.setState({status:"SignIn"});
            }
        }
    }catch(err){
        console.log(err);
        this.setState({status:"SignIn"});
    }

    
    let qmatch = window.location.href.match(/\?(.+)?/)
    //console.log(qmatch);
    let qstring = {}
    
    if(qmatch){
        let params = qmatch[1].split('&');
        for(let p of params)
        {
        let parts = p.split('=');
        qstring[parts[0]]=parts[1];
        }
        this.setState({ mode : qstring['mode']||'face'});
    }

   
   
  }

  
  // Handle changes to form inputs on sign-in
  handleFormInput = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  resetForm = event =>{
    this.setState({
        username:'',password:''
    })
  }
  displayUserFeedback = (message,severity,title, icon) =>{
    this.setState({
        userfeedback: message,
        severity: severity ||'info',
        title: title || '',
        icon: icon || 'InfoIcon'
    })
  }
  
  switchComponent = status => {
    this.setState({ status });
  };


  AuthComponent = () => {

    
   

    switch (this.state.status) {
      case "SignIn":
        //display the sign-in form and manage custom signin events
        return (
            
            <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            sx={{ minHeight: '100vh' }}
          >
            <Grid item xs={3}>
                <div>
                        <div style={{minHeight:'100px'}}>
                            <Alert severity={this.state.severity} icon={<IconSelector icon={this.state.icon} />} style={{visibility: (this.state.userfeedback!='') ? 'visible' : 'hidden' }}>
                                <AlertTitle>{this.state.title}</AlertTitle>
                                {this.state.userfeedback}
                            </Alert>
                        </div>
                        <SignIn
                            switchComponent={this.switchComponent}
                            handleFormInput={this.handleFormInput}
                            displayUserFeedback={this.displayUserFeedback}
                            resetForm={this.resetForm}
                            inputs={this.state}
                        />
                    
                </div>
            </Grid>
          </Grid>
          
            
        );
        
      case "content":
        //display auth protected content
        return (
           <AuthContent switchComponent={this.switchComponent}/>
        );
      
        
    }
  };
  
  render() {
    return <div>{this.AuthComponent()}</div>;
  }
}