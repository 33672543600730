import React, { Component, useState, useEffect} from "react";
import { Auth, API } from "aws-amplify";
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';


import SignInGroup from "./SignInGroup";
import { Container } from "@mui/material";
import OpenFaceAuthAppDialogue  from "./OpenFaceAuthAppDialogue.js"


let feedbackmessage;
export default class SignIn extends Component {
    
  state = {
    mode: "face",
    showFaceAuthenticatorAppDialogue: false,
    handleSignInCallback:undefined
  };

  


  authrequestid;
  authcanceled=false;


  async componentDidMount() {
    
    let qmatch = window.location.href.match(/\?(.+)?/)
    //console.log(qmatch);
    let qstring = {}
    
    if(qmatch){
        let params = qmatch[1].split('&');
        for(let p of params)
        {
        let parts = p.split('=');
        qstring[parts[0]]=parts[1];
        }
        this.setState({ mode : qstring['mode']||'face'});
    }
   
   
  }

  promptUser = async(event,callback) =>{
    
    if(callback){
      this.setState({handleSignInCallback:callback});
    }

    if(this.state.mode=='face'){
      //console.log('show face auth dialogue')
      this.setState({showFaceAuthenticatorAppDialogue:true});
    }else{
      this.handleSignIn(event);
    }

  }

  handleSignIn = async(event) => {
    
    //event.preventDefault();
    this.setState({showFaceAuthenticatorAppDialogue:false});
    const delay = ms => new Promise(res => setTimeout(res, ms));
    const { username, password } = this.props.inputs;
    this.authcanceled=false;
    
    // You can pass an object which has the username, password and validationData which is sent to a PreAuthentication Lambda trigger
    
    try {
        //(message,severity,title, icon)
        this.props.displayUserFeedback('Username and password authentication initiated.','info','Processing',"info");
        let user = await Auth.signIn(username, password);
        //console.log('username and password auth result',user);
        if (user && user.challengeName && user.challengeName === 'CUSTOM_CHALLENGE') {
          
          try {

              
                this.props.displayUserFeedback('Continue with your Face Authenticator App.','info','Processing','info');
                //console.log('custom challenge received with requestid: ',user.challengeParam.securityQuestion);
                this.authrequestid = user.challengeParam.securityQuestion;
                // to send the answer of the custom challenge
    
                const checkAuthStatus = async() =>{
                    return new Promise(async (resolve,reject)=>{
    
                        let authrequestid = user.challengeParam.securityQuestion;
                        let reqconfig = {
                            headers:{ 'Content-Type': 'application/json'},response:true,queryStringParameters:{ authrequestid: authrequestid}
                        }
    
                       
                    
                        let reqcount = 0;
                        let httpres;
                        let challengetoken;
                        do{
                            await delay(1000);
                            try{
                            httpres = await API.get('public','/faces/search/result',reqconfig);
                            challengetoken = (httpres && httpres.data && httpres.data.challengeToken)?httpres.data.challengeToken:undefined;
                            }catch(err){
                                console.log(err);
                            }
                            reqcount++;
                            if(!(this.authcanceled)){
                              this.props.displayUserFeedback('Awaiting Face Authenticator App results.','info','Processing',"info");
                            }
                        }while(reqcount<60 && httpres.status!=200 && !(this.authcanceled))
                        if(challengetoken){
                          this.props.displayUserFeedback('Verifying Face Authenticator App results.','info','Verifying',"info");
                        }
                        resolve(challengetoken);
                    })
                }
                
                let challengetoken = await checkAuthStatus();
                if(challengetoken){
                  const challengeAnswerResponse = await Auth.sendCustomChallengeAnswer(
                    user,
                    challengetoken
                  );
                  //console.log(challengeAnswerResponse);
                  this.props.displayUserFeedback('Face Authentication successful.','success','Success',"verified");
                  await delay(1000);
                  this.props.resetForm();
                  this.props.switchComponent("content");
                  //reset feedback
                  this.props.displayUserFeedback('');
                }else if(!(this.authcanceled)){
                  this.props.displayUserFeedback('Face Authentication failed.  Please try again.','warning','Authentication Failed',"warn");
                 
                }
              
           
          } catch (err) {
            console.log(err);
          }
        }else{
          this.props.displayUserFeedback('');
          this.props.resetForm();
            this.props.switchComponent("content");
        }
      } catch (err) {
        if(err.name=='NotAuthorizedException'){
          this.props.displayUserFeedback('Authentication failed.  Please try again.','warning','Authentication Failed',"warn");
          
        }else{
          console.log('error authenticating user',err);
        }
      }
      if(this.state.handleSignInCallback){
        this.state.handleSignInCallback();
      }
      return true;

  };

  handleCancel = async(event) => {
    //get cancel for requestid
    //console.log('cancel authrequestid:',this.authrequestid)
    this.authcanceled=true;
    let cancelconfig = {
      headers:{ 'Content-Type': 'application/json'},response:true,queryStringParameters:{ authrequestid: this.authrequestid}
    }
    if(this.state.mode=='face'){
      try{

      let cancelreq = await API.get('public','/faces/search/quit',cancelconfig);
      }catch(err){
        console.log(err);
      }
    }
    this.props.displayUserFeedback('Authentication canceled.','warning','Authentication Canceled',"warn");

  }
  
  isValidEmail = (email)=>{
      if(email) {
        return email.toString().match(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          );
      }else{
        return false;
      }
    
  }

  isValidPassword = (password)=>{
    if(password){
      return (password.length>=6);
    }else{
      return false;
    }
  }

  render() {
    return (
      <Container style={{width:'800px'}}>
      <OpenFaceAuthAppDialogue showDialogue={this.state.showFaceAuthenticatorAppDialogue} onClose={this.handleSignIn} />
        <Box sx={{ '& > button': { m: 1 }}}>
            <Box sx={{ '& > button': { m: 10 } }}>
                <TextField
                required
                id="outlined-required"
                name="username"
                fullWidth
                margin="normal"
                label="Email Address"
                value={this.props.username}
                onChange={this.props.handleFormInput}
                
                />
            </Box>
            <Box sx={{ '& > button': { m: 10 } }}>
                <TextField
                id="outlined-password-input"
                name="password"
                type="password"
                required
                autoComplete="current-password"
                fullWidth
                margin="normal"
                label="Password"
                value={this.props.password}
                onChange={this.props.handleFormInput}
                />
            </Box>
            <Box sx={{ '& > button': { m: 10 } }}>
                <SignInGroup onClick={this.promptUser} onCancel={this.handleCancel} disabled={(!((this.isValidEmail(this.props.inputs.username)) && (this.isValidPassword(this.props.inputs.password)))) } />
            </Box>
        </Box>
    </Container>
      
    );
  }
}