
import React, { PureComponent, useState } from "react";
import SignIn from "./SignIn";
import IconSelector from "./IconSelector";
import { Alert, AlertTitle } from "@mui/material";
import Grid from '@mui/material/Grid';
import SignOut from './SignOut';

import Box from '@mui/material/Box';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import CircularProgress from '@mui/material/CircularProgress';

import { GetAuthenticationHistory } from '../libs/authchallenges.js'
import '../css/content.css'

export default class AuthenticationHistory extends PureComponent {
    
    state = {
          columns:[]
        , rows:[]
        , loading:true
    };

    
    async componentDidMount() {

        GetAuthenticationHistory().then((data)=>{
            //console.log(data);
            /*[
                    { id: 1, lastName: 'Snow', firstName: 'Jon', age: 35 },
                    { id: 2, lastName: 'Lannister', firstName: 'Cersei', age: 42 },
                    { id: 3, lastName: 'Lannister', firstName: 'Jaime', age: 45 },
                    { id: 4, lastName: 'Stark', firstName: 'Arya', age: 16 },
                    { id: 5, lastName: 'Targaryen', firstName: 'Daenerys', age: null },
                    { id: 6, lastName: 'Melisandre', firstName: null, age: 150 },
                    { id: 7, lastName: 'Clifford', firstName: 'Ferrara', age: 44 },
                    { id: 8, lastName: 'Frances', firstName: 'Rossini', age: 36 },
                    { id: 9, lastName: 'Roxie', firstName: 'Harvey', age: 65 },
                ]*/
            this.setState({
                 columns: [
                     { field: 'created_date', headerName: 'Initiated', width: 200 }
                    ,{ 
                          field: 'duration'
                        , headerName: 'Duration'
                        , description: 'Duration in seconds for the user and backend services to complete the authentication cycle.'
                        , valueGetter: (params) =>{
                            return (new Date(params.row.resultdate)-new Date(params.row.created_date))/1000;
                        }
                    }
                    ,{ 
                        field: 'authresult'
                      , headerName: 'Result'
                      , width: 110 
                      , valueGetter: (params) =>{
                        return (params.row.authresult)?'SUCCESS':'FAIL';
                      }
                    }
                    ,{
                      field: 'awssimilarity',
                      headerName: 'AWS Confidence',
                      description: 'AWS Confidence that authentication photo matches photo of record',
                      editable: false,
                      width: 150,
                      valueGetter: (params) =>
                        `${Math.round(params.row.matchresult.Similarity*1000000)/1000000}%`,
                    }
                    ,{
                        field: 'awsfacedetection',
                        headerName: 'Attributes',
                        description: 'Attributes of the detected face.',
                        editable:false,
                        width:350,
                        renderCell: (params)=>{
                            return (params.row.awsfacedetection?.Pose)?
                            <div style={{display:'block'}}>
                                
                                <div>Pitch: {params.row.awsfacedetection.Pose.Pitch}</div>
                                <div>Yaw: {params.row.awsfacedetection.Pose.Yaw}</div>
                                <div>Roll: {params.row.awsfacedetection.Pose.Roll}</div>
                            </div>
                            :
                            <></>
                        }
                        
                    }
                    ,{
                        field: 'sourceimage',
                        headerName: 'Source Image',
                        description: 'Source image used for authentication.',
                        editable: false,
                        width:350,
                        renderCell: (params)=>{
                            return (params.row.sourceimage)?
                            <div>
                                <img src={params.row.sourceimage.PreSignedUrl} style={{height:'200px'}} />
                            </div>
                            :
                            <></>
                        }

                    }          
                  ]
                , rows:data
                , loading:false
            })
            
        })

    }

    
    render(){
        return (
        
            <>
                <div className="pageHeader">
                    Face Authentication History
                </div>
                <Box maxWidth={true} maxHeight={true}>
                    {(!this.state.loading)?
                        <DataGrid
                            rows={this.state.rows}
                            columns={this.state.columns}
                            initialState={{
                            pagination: {
                                paginationModel: {
                                pageSize: 10,
                                },
                            },
                            }}
                            pageSizeOptions={[5,10,25,50,100]}
                            //onRowSelectionModelChange={(val)=>alert(val)}
                            getRowId={(row)=>{return row.authrequestid}}
                            disableRowSelectionOnClick
                            rowHeight={200}
                        />
                    :<CircularProgress />
                    }
                    </Box>
               
            </>
            
        
        );

                    
    }
}