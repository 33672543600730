
import React, { PureComponent } from "react";
import SignIn from "./SignIn";
import IconSelector from "./IconSelector";
import { Alert, AlertTitle } from "@mui/material";
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import SignOut from './SignOut';
import AppRouter from './AppRouter';
import FaceAuthCompare from "./AuthenticationHistory";
import '../css/nav.css';

export default class AuthContent extends PureComponent {

    async componentDidMount() {

    }

    
    render(){
        /*
            <div className="menuItem">
                <a href="/faceauthcompare">Authentication History</a>
            </div>
            <div className="menuItem">
                <a href="/devices">Devices</a>
            </div>
            <div className="menuItem">
                <a href="/userinfo">User Info</a>
            </div>
        
                        */
        return (
                <>
                <div style={{height:'100vh'}}>
                    <div style={{width:'100%',textAlign:'right',padding:'10px', display:'inline-block'}}>
                        
                        <SignOut 
                            switchComponent={this.props.switchComponent}
                        />
                    </div>
                    <AppRouter />
                </div>
                </>
        );
    }
}