import React, { useState, useEffect } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

import SendIcon from '@mui/icons-material/Send';
import CancelIcon from '@mui/icons-material/Cancel';
import Box from '@mui/material/Box';



export default function SignInGroup (props){
    const [loading, setLoading] = useState(false);
    const [mode, setMode] = useState('face');

    
    useEffect(() => {
        let qmatch = window.location.href.match(/\?(.+)?/)
        let qstring = {}
        
        if(qmatch){
            let params = qmatch[1].split('&');
            for(let p of params)
            {
            let parts = p.split('=');
            qstring[parts[0]]=parts[1];
            }
            setMode(qstring['mode']||'face');
        }
    },[]);
    

    async function handleClick(event){
        setLoading(true);
        if(props.onClick){
            props.onClick(event,()=>{
                setLoading(false);
            });
        } 
        //setLoading(false);
    }

    async function handleCancel(event){
        if(props.onCancel)
            await props.onCancel(event)

        setLoading(false);
    }

    
    return (
        <Stack spacing={2} direction="row">

            <LoadingButton
            onClick={handleClick}
            endIcon={<SendIcon />}
            loading = {loading} 
            loadingPosition="end"
            variant="contained"
            disabled={props.disabled}
            >Sign In</LoadingButton>

            <Button
                onClick={handleCancel}
                endIcon={<CancelIcon />}
                variant="contained"
                disabled={!(loading)}
                style={{visibility: (loading) ? 'visible' : 'hidden' }}
            >Cancel</Button>
            
            <div style={{textAlign:'right',width:'60%'}}>
                <span style={{padding:'10px', backgroundColor:(mode=='face')?'#C0C0C0':'#FFFFFF'}}>{(mode=='face')?'Face Authentication':<a href="?mode=face">Face Authentication</a>}</span>
                <span style={{padding:'10px', backgroundColor:(mode=='legacy')?'#C0C0C0':'#FFFFFF'}}>{(mode=='legacy')?'Legacy Authentication':<a href="?mode=legacy">Legacy Authentication</a>}</span>
            </div>
            

        </Stack>
    )
  
}