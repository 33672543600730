import './App.css';
import React, {useEffect} from 'react';
import awsconfig from './config/awsconfig';
import '@aws-amplify/ui-react/styles.css';
import { Authenticator} from '@aws-amplify/ui-react';
import { Amplify } from 'aws-amplify';

import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

import { FaceAuthClass } from './components/AuthHub';
import Authentication  from './components/Authentication';
import AuthContent from './components/AuthContent';


let myauth = new FaceAuthClass();

const App =()=>{
    useEffect(() => {
      document.title = 'Face Authenticator';
    }, []);
    
    let url = window.location.href;
    let qmatch = url.match(/\?(.+)?/)
    let qstring = {}
    let mode='face';
    let loginscreen='custom';
    
    if(qmatch){
      let params = qmatch[1].split('&');
      for(let p of params)
      {
        let parts = p.split('=');
        qstring[parts[0]]=parts[1];
      }
      mode = qstring['mode']||'face';
      loginscreen = qstring['screen']||'custom';
    }

    if(mode.toLowerCase()=='legacy'||loginscreen=='builtin'){
      //if legacy authentication or built-in screen, clear the CUSTOM_CHALLENGE flow and use provider defaults
      awsconfig.Auth.authenticationFlowType = undefined
    }

    Amplify.configure(awsconfig);

    switch(loginscreen){
      case 'builtin':
        //use the built-in aws-amplify-ui for signin/signup
        return (
          <>
            <CssBaseline />
            <Container>
              <Box sx={{ height: '100vh' }}>
              <Authenticator>
                  <AuthContent />
              </Authenticator>
              </Box>
            </Container>
          </>
        )
      break;
      default:
        //use the custom face authentication flow
      return (
        <>
        <CssBaseline />
        <Container>
          <Box sx={{ height: '100vh' }}>
            <Authentication />
          </Box>
        </Container>
        </>
      )
    }
      
    
    
    
    
      
      
   
  
  
}

export default App;
