import React, { Component } from "react";
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

import LogoutIcon from '@mui/icons-material/Logout';

import { Auth } from "aws-amplify";

export default class SignOut extends Component {
  
  signOut= async(event) => {
    event.preventDefault();
    Auth.signOut();
    if(this.props.switchComponent)
        this.props.switchComponent('SignIn');
  }

  render() {
    return (
        <Button
        onClick={this.signOut}
        endIcon={<LogoutIcon />}
        variant="contained"
    >Sign Out</Button>
    );
  }
}