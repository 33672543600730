import React from 'react';
import { BrowserRouter, Routes, Route, Router, Redirect } from 'react-router-dom';
import Devices from './Devices';
import AuthenticationHistory from './AuthenticationHistory';
import UserInfo from './UserInfo';

function AppRouter(){
    //<Route path="/devices" element={<Devices />} />
    //<Route path="/userinfo" element={<UserInfo />} />
    return (
        
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<AuthenticationHistory />} />
                <Route path="/history" element={<AuthenticationHistory />} />
            </Routes>
        </BrowserRouter>
        
    )
}

export default AppRouter;