import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function OpenFaceAuthAppDialogue(props) {
  const [open, setOpen] = React.useState(props.showDialogue||false);

  
  const handleClose = () => {
    setOpen(false);
    if(props.onClose){
        props.onClose();
    }
  };

  return (
    <div>
      <Dialog
        open={props.showDialogue}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Is your Face Authenticator running?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Authentication requires your Face Authenticator app to be running on your device.  Please open your Face Authenticator App now.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Continue</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}