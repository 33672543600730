import { Auth } from 'aws-amplify';

export default (
{
    Auth: {
    mandatorySignIn: true,
    region: process.env.REACT_APP_REGION,
    userPoolId: process.env.REACT_APP_USERPOOLID,
    userPoolWebClientId: process.env.REACT_APP_USERPOOLWEBCLIENTID,
    authenticationFlowType: "CUSTOM_AUTH"
    },
    API: {
        endpoints: [
            {
                name: "default",
                endpoint: process.env.REACT_APP_APIGWENDPOINT,
				custom_header: async () => { 
				  return { 'Authorization': `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` }
				}
            },
            {
                name: "public",
                endpoint: process.env.REACT_APP_APIGWENDPOINT
            }
        ]
    }
}
)


