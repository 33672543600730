import { API, Amplify} from 'aws-amplify';


const apiName = 'default';

const GetAuthenticationHistory = async()=>{
    //console.log('getting authentication history info');
    return API.get(apiName, '/user/history');        
}

export { GetAuthenticationHistory }
